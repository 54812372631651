import React, { useEffect, useContext } from "react"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql, Link } from "gatsby"
import { useQuery } from "@apollo/react-hooks"
import gql from "graphql-tag"

import "../../graphql/fragments"

import SEO from "../../components/seo"
import Layout from "../../components/layout"

import { Section, Container } from "../../components/grid/grid"

import icMoveQuickly from "../../assets/img/icons/icMoveQuickly.png"
import suistainabilityIcon from "../../assets/img/icons/icSuistainability.png"
import developmentIcon from "../../assets/img/icons/icDevelopment.png"
import careerIcon from "../../assets/img/icons/icCareer.png"
import brandLogoMain from "../../assets/img/logo_sinarmas-dark.png"
import fbCircle from "../../assets/img/icons/crFacebook.png"
import twCircle from "../../assets/img/icons/crxTwitter.png"
import igCircle from "../../assets/img/icons/crInstagram.png"
import youtubeCircle from "../../assets/img/icons/crYoutube.png"
import linkedinCircle from "../../assets/img/icons/crLinkedin.png"
import tiktokCircle from "../../assets/img/icons/crTiktok.png"
import globeIcon from "../../assets/img/icons/icGlobe.png"

import "@glidejs/glide/dist/css/glide.core.min.css"
import "@glidejs/glide/dist/css/glide.theme.min.css"

const GET_REPORTS = gql`
  query GetFiles {
    fileCategory(id: "annual", idType: SLUG) {
      slug
      filesPdf(first: 5) {
        edges {
          cursor
          node {
            title
            acfFile {
              company
              year
              file {
                guid
                mediaItemUrl
              }
            }
          }
        }
        pageInfo {
          hasPreviousPage
          hasNextPage
          startCursor
          endCursor
        }
      }
    }
  }
`

const SocialmediaPage = location => {

  useEffect(() => {

    if (location.uri == '/socialmedia'){
      return(
        document.getElementById('navbarDesktop').classList.add('d-none'),
        document.getElementById('navbarMobile').classList.add('d-none'),
        document.getElementsByClassName('animated')[0].classList.add('mt-0'),
        document.getElementsByClassName('footer-top')[0].classList.add('d-none'),
        document.getElementsByClassName('footer-scroll')[0].classList.add('d-none'),
        document.getElementsByClassName('footer-bottom')[0].classList.add('d-none'),
        document.getElementsByClassName('navbar-nav-main')[0].classList.add('d-none'),
        document.querySelector('footer .red-ribbon .ribbon.black').style.background = "#fff",
        document.querySelector('footer .red-ribbon .ribbon.red').style.background = "linear-gradient(to right, #ab0203, #f00000) !important", 

        console.log('true')
      )
    }else{
      console.log('false')
    }
    
  }, [])

  const data = useStaticQuery(graphql`
    query {
      imgCoverMain: file(relativePath: { eq: "imgBannerSinarmas.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1500) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      featured: file(relativePath: { eq: "img-featured-news-sample.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1125) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      wordPress {
        pageBy(uri: "general-information") {
          title
          slug
          blocks {
            ...CoreParagraphBlock
            ...CoreHeadingBlock
            ...CoreButtonBlock
            ...CoreCoverBlock
            ...CoreShortcodeBlock
            ...AcfIconListBlock
            ...CoreGroupBlock
            ...CoreEmbedYoutube
          }
          seo {
            title
            twitterDescription
            metaDesc
            opengraphDescription
          }
        }
      }
    }
  `)

  const { data: annualReports } = useQuery(GET_REPORTS)

  const wordPress = data.wordPress.pageBy.blocks
  const seo = data.wordPress.pageBy.seo
  const cover =
    wordPress.find(x => x.name === "core/cover") === wordPress[0] &&
    wordPress[0]

  return (
    <Layout location={location} currentLocation={location.uri}>
      <SEO title="Social Media" description={seo.metaDesc} />
      <Helmet>
        <body className="bd-page" />
      </Helmet>
      <Section className="bg-light lineupSosmed">&nbsp;</Section>
      <Section className="bg-light mainSosmed">
        <Container className="text-center">
          <div className="col-md-12 mb-4">
            <a href="/">
              <img src={brandLogoMain} className="logoBrand"/>
            </a>
          </div>
          <div className="col-md-12 mb-4">Follow Our Social media</div>
          <div className="col-md-12 mb-4">
            <a href="https://www.facebook.com/sinarmasland/" className="circleIcon" target="_blank"><img className="circle" src={fbCircle}/></a>
            <a href="https://www.twitter.com/sinarmas_land" className="circleIcon" target="_blank"><img className="circle" src={twCircle}/></a>
            <a href="https://www.instagram.com/sinarmas_land/" className="circleIcon" target="_blank"><img className="circle" src={igCircle}/></a>
            <a href="https://www.youtube.com/user/sinarmasland?sub_confirmation=1" className="circleIcon" target="_blank"><img className="circle" src={youtubeCircle}/></a>
            <a href="https://www.linkedin.com/company/sinar-mas-land/" className="circleIcon" target="_blank"><img className="circle" src={linkedinCircle}/></a>
            <a href="https://www.tiktok.com/@sinarmas_land" className="circleIcon" target="_blank"><img className="circle" src={tiktokCircle}/></a>
          </div>
          <div className="col-md-12" style={{padding: "15px"}}>More Information: </div>
        </Container>
        <Container>
          <div className="row col-md-12 btnLine">
            <div className="col-md-4">
              <div class="btn-rounded">
                <a href="https://www.sinarmasland.com/move-in-quickly/" target="_blank">
                  <div className="lineUp">
                    <div className="icon"><img src={icMoveQuickly} style={{width:"36px !important", paddingTop:"7px !important"}}/></div>
                    <div className="desc">Move in Quickly</div>
                  </div>
                </a>
              </div>
            </div>
            <div className="col-md-4">
              <div class="btn-rounded">
                <a href="https://www.sinarmasland.com/news/blog" target="_self">
                  <div className="lineUp">
                    <div className="icon" style={{lineHeight: "50px"}}>
                      <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 2048 1408">
                        <path fill="#ab0204" d="M1024 384H640v384h384zm128 640v128H512v-128zm0-768v640H512V256zm640 768v128h-512v-128zm0-256v128h-512V768zm0-256v128h-512V512zm0-256v128h-512V256zM256 1216V256H128v960q0 26 19 45t45 19t45-19t19-45m1664 0V128H384v1088q0 33-11 64h1483q26 0 45-19t19-45M2048 0v1216q0 80-56 136t-136 56H192q-80 0-136-56T0 1216V128h256V0z"/>
                      </svg>
                    </div>
                    <div className="desc">Blog</div>
                  </div>
                </a>
              </div>
            </div>
            <div className="col-md-4">
              <div class="btn-rounded">
              <a href="https://sinarmasland.com/sustainability?utm_source=Social_Media&utm_medium=FBIGOrganic&utm_campaign=Digital_WFH_2021&utm_content=Linktree_IG_SML_Sustainability" target="_blank">
                  <div className="lineUp">
                    <div className="icon"><img src={suistainabilityIcon}/></div>
                    <div className="desc">Sustainability</div>
                  </div>
                </a>
              </div>
            </div>
          </div>
          <div className="row col-md-12 btnLine">
          <div className="col-md-4">
              <div class="btn-rounded">
              <a href="https://sinarmasland.com/development?utm_source=Social_Media&utm_medium=FBIGOrganic&utm_campaign=Digital_WFH_2021&utm_content=Linktree_IG_SML_Development" target="_blank">
                  <div className="lineUp">
                    <div className="icon"><img src={developmentIcon}/></div>
                    <div className="desc">Development</div>
                  </div>
                </a>
              </div>
            </div>
            <div className="col-md-4">
              <div class="btn-rounded">
              <a href="https://sinarmasland.com/career?utm_source=Social_Media&utm_medium=FBIGOrganic&utm_campaign=Digital_WFH_2021&utm_content=Linktree_IG_SML_Career" target="_blank">
                  <div className="lineUp">
                    <div className="icon"><img src={careerIcon}/></div>
                    <div className="desc">Career</div>
                  </div>
                </a>
              </div>
            </div>
            <div className="col-md-4">
              <div class="btn-rounded">
              <a href="https://www.sinarmasland.com/precious/" target="_blank">
                  <div className="lineUp">
                    <div className="icon"><img src={globeIcon}/></div>
                    <div className="desc">Sinar Mas Land Precious</div>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </Container>
      </Section>
    </Layout>
  )
}

export default SocialmediaPage
