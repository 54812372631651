import React, { useEffect, useState, useContext } from "react"
import { graphql, useStaticQuery, navigate } from "gatsby"
import { useLazyQuery, useQuery } from "@apollo/react-hooks"
import gql from "graphql-tag"
import { useMediaPredicate } from "react-media-hook"

import Layout from "../components/layout"
import SEO from "../components/seo"

import { LangContext } from "../context/langContext"

import { CardSearchPrice } from "../components/card/card"
import { Section, Container } from "../components/grid/grid"
import { FormSMLHome } from "../components/forms-sml/forms-sml"

import "@glidejs/glide/dist/css/glide.core.min.css"
import "@glidejs/glide/dist/css/glide.theme.min.css"

const FIND_BY_KEYWORD_V2 = gql`
  query Search(
    $key: String
    $first: Int
    $last: Int
    $after: String
    $before: String
    $language: LanguageCodeFilterEnum
  ) {
    contentNodes(
      where: {
        search: $key
        contentTypes: [PAGE, POST, PROJECT]
        language: $language
      }
      first: $first
      last: $last
      after: $after
      before: $before
    ) {
      edges {
        cursor
        node {
          __typename
          ... on Project {
            title
            excerpt
            slug
            propertyTypes {
              nodes {
                name
              }
            }
            locations {
              nodes {
                name
                slug
              }
            }
            translation(language: ID) {
              title
              excerpt
              slug
              propertyTypes {
                nodes {
                  name
                }
              }
            }
          }
          ... on Post {
            id
            title
            slug
            excerpt
            translation(language: ID) {
              id
              title
              slug
              excerpt
            }
          }
          ... on Page {
            title
            slug
            excerpt
            translation(language: ID) {
              title
              slug
              excerpt
            }
          }
        }
      }
      pageInfo {
        endCursor
        startCursor
        total
        hasPreviousPage
        hasNextPage
      }
    }
  }
`

const FIND_PROPERTY = gql`
  query Finder(
    $facilityId: [ID]
    $locationId: [ID]
    $typeId: [ID]
  ) {
    projects(
      first: 1000
      where: {
        facilityIn: $facilityId
        locationIn: $locationId
        propertyTypeIn: $typeId
      }
    ) {
      edges {
        cursor
        node {
          databaseId
          title
          slug
          excerpt
          date
          propertyTypes {
            nodes {
              name
              slug
            }
          }
          featuredImage {
            caption
            sourceUrl
          }
          priceProduct {
            price
          }
          locations {
            nodes {
              name
              slug
            }
          }
          facilities {
            nodes {
              name
              slug
              facilityId
              facilityIcon {
                icon {
                  sourceUrl
                }
              }
            }
          }
        }
      }
    }
  }
`

const QUERY_STATIC_FACILITIES = graphql`
  query GetFacilities {
    wordPress {
      facilities(first: 100) {
        nodes {
          name
          slug
          facilityId
          projects {
            nodes {
              propertyTypes {
                nodes {
                  slug
                  name
                  projects {
                    nodes {
                      slug
                    }
                  }
                }
              }
            }
          }
        }
      }
      locations(first: 100) {
        nodes {
          locationId
          slug
          name
          projects {
            nodes {
              title
              priceProduct {
                price
              }
            }
          }
        }
      }
      propertyTypes(where: {language: EN}, first: 100) {
        nodes {
          name
          slug
          propertyTypeId
          children {
            nodes {
              slug
              name
              projects(first: 1000) {
                nodes {
                  title
                  slug
                  priceProduct {
                    price
                  }
                }
              }
            }
          }
        }
      }

      categories {
        nodes {
          name
          posts(first: 1000) {
            nodes {
              slug
            }
          }
        }
      }
      projects(first: 1000) {
        nodes {
          id
          projectTags {
            edges {
              node {
                name
              }
            }
          }
          title
          slug
          excerpt
          priceProduct {
            price
          }
          locations {
            nodes {
              name
              slug
            }
          }
          propertyTypes(first: 100) {
            nodes {
              name
              slug
            }
          }
        }
      }
      page(id: "career", idType: URI) {
        title
        slug
        excerpt
      }
    }
  }
`

const GET_PROPERTY_TYPES = gql`
  query GetPropertyTypes {
    propertyTypes(where: {language: EN}, first: 100) {
      nodes {
        name
        slug
        propertyTypeId
      }
    }
  }
`

const changeParentSlug = (slug) => {
  switch(slug) {
    case "kavling-doubledream-property":
      slug = "kavling";
      break;
    case "residential-doubledream-property":
      slug = "residential";
      break;
  }
  return slug
}

const Pagination = ({ hasPreviousPage, hasNextPage, prevPage, nextPage }) => {
  if (hasNextPage || hasPreviousPage) {
    return (
      <div className="d-flex justify-content-end">
        <div className="paginate">
          <button
            className="btn btn-primary pagination-button"
            disabled={!hasPreviousPage}
            onClick={prevPage}
          >
            <i className="fas fa-chevron-left" />
          </button>
          <button
            className="btn btn-primary pagination-button"
            disabled={!hasNextPage}
            onClick={nextPage}
          >
            <i className="fas fa-chevron-right" />
          </button>
        </div>
      </div>
    )
  } else {
    return <div className="pagination"></div>
  }
}

const createParams = (obj, prefix) => {
  let arr = []
  for (let p in obj) {
    if(obj[p] !== "" && obj[p] !== undefined){
      if (obj.hasOwnProperty(p)) {
        const k = prefix ? prefix : p
        const v = obj[p]

        arr.push(typeof v == "object" 
          ? createParams(v, k) 
          : encodeURIComponent(k) + "=" + encodeURIComponent(v));
      }
    }
  }
  return arr.join("&")
}

export const SearchPriceResult = ({ location }) => {
  const offset = 0 
  const perPage = 12
  const resizeScreen = useMediaPredicate("(max-width: 992px)")
  const [total, setTotal] = useState(null)
  const [totalRelated, setTotalRelated] = useState(null)
  const [lastLocations, setLastLocations] = useState(null)
  const { lang } = useContext(LangContext)
  const [findResult, setFindResult] = useState({
    resultValues: [],
  })
  const [findProperty, setFindProperty] = useState({
    location: null,
    propertyTypes: null,
    projects: null,
    fromPrice: null,
    toPrice: null,
    checkBoxValues: [],
  })
  const [pagination, setPagination] = useState({
    offset,
    perPage
  })
  const [filteredProjects, setFilteredProjects] = useState([])
  const [relatedProjects, setRelatedProjects] = useState([])
  const [isSubmitFilter, setSubmitFilter] = useState(false)

  const data = useStaticQuery(QUERY_STATIC_FACILITIES)

  const { data: dataPropertyTypes } = useQuery(GET_PROPERTY_TYPES)
  //prettier-ignore
  const [ getProperty, { data: properties, loading: loadingProperties }] = useLazyQuery(FIND_PROPERTY)
  //prettier-ignore
  const [getResult, { data: searchResult, loading }] = useLazyQuery(FIND_BY_KEYWORD_V2)

  const listFacilities = data.wordPress.facilities.nodes
  const listLocations = data.wordPress.locations.nodes
  const listPropertyTypes = dataPropertyTypes?.propertyTypes?.nodes || data.wordPress.propertyTypes.nodes
  const listDataProjects = data.wordPress.projects.nodes

  const propertyFinderResult = properties && properties.projects.edges
  const result = searchResult && searchResult.contentNodes.edges

  let defaultPropertyTypesId = []

  listPropertyTypes.map(item =>
    defaultPropertyTypesId.push(item.propertyTypeId)
  )

  let listStaticLocations = []
  let listStaticPropertyTypes = []
  let listStaticProjects = []

  const allLocation = { label: "All Location", value:"" }
  listLocations.map(item =>
    listStaticLocations.push({ label: item.name, value: item.slug })
  )

  listStaticLocations.splice(0, 0, allLocation)

  listPropertyTypes.map(item =>
    item.slug !== "doubledream-faq" && 
    item.slug !== "faq" && 
    item.slug !== "infiniteliving-faq" && 
    item.slug !== "properti" && 
    item.slug !== "doubledream-property" && 
    item.slug !== "smartmove-faq" && 
    item.slug !== "smartmove-property" && 
    listStaticPropertyTypes.push({ label: item.name, value: item.slug })
  )

  properties &&
  properties.projects.edges.map(item =>
    listStaticProjects.push({ label: item.node.title, value: item.node.slug })
  )
  // console.log('properties', properties)

  // min. price data
  const minPriceData = [
    {label: "Min. Price (Rp)", value: 0},
    {label: "Rp 500jt", value: 500000000},
    {label: "Rp 600jt", value: 600000000},
    {label: "Rp 700jt", value: 700000000},
    {label: "Rp 800jt", value: 800000000},
    {label: "Rp 900jt", value: 900000000},
    {label: "Rp 1M", value: 1000000000},
    {label: "Rp 2M", value: 2000000000},
    {label: "Rp 3M", value: 3000000000},
    {label: "Rp 4M", value: 4000000000},
    {label: "Rp 5M", value: 5000000000},
    {label: "Rp 6M", value: 6000000000},
    {label: "Rp 7M", value: 7000000000},
    {label: "Rp 8M", value: 8000000000},
    {label: "Rp 9M", value: 9000000000},
    {label: "Rp 10M", value: 10000000000},
    {label: "Rp 11M", value: 11000000000},
    {label: "Rp 12M", value: 12000000000},
    {label: "Rp 13M", value: 13000000000},
    {label: "Rp 14M", value: 14000000000},
    {label: "Rp 15M", value: 15000000000},
    {label: "Rp 16M", value: 16000000000},
    {label: "Rp 17M", value: 17000000000},
    {label: "Rp 18M", value: 18000000000},
    {label: "Rp 19M", value: 19000000000},
    {label: "Rp 20M", value: 20000000000},
  ]
  
  // max. price data
  const maxPriceData = [
    {label: "Max. Price (Rp)", value: 0},
    {label: "Rp 500jt", value: 500000000},
    {label: "Rp 600jt", value: 600000000},
    {label: "Rp 700jt", value: 700000000},
    {label: "Rp 800jt", value: 800000000},
    {label: "Rp 900jt", value: 900000000},
    {label: "Rp 1M", value: 1000000000},
    {label: "Rp 2M", value: 2000000000},
    {label: "Rp 3M", value: 3000000000},
    {label: "Rp 4M", value: 4000000000},
    {label: "Rp 5M", value: 5000000000},
    {label: "Rp 6M", value: 6000000000},
    {label: "Rp 7M", value: 7000000000},
    {label: "Rp 8M", value: 8000000000},
    {label: "Rp 9M", value: 9000000000},
    {label: "Rp 10M", value: 10000000000},
    {label: "Rp 11M", value: 11000000000},
    {label: "Rp 12M", value: 12000000000},
    {label: "Rp 13M", value: 13000000000},
    {label: "Rp 14M", value: 14000000000},
    {label: "Rp 15M", value: 15000000000},
    {label: "Rp 16M", value: 16000000000},
    {label: "Rp 17M", value: 17000000000},
    {label: "Rp 18M", value: 18000000000},
    {label: "Rp 19M", value: 19000000000},
    {label: "Rp 20M", value: 20000000000},
  ]

  //const maxPriceDefault = Math.max.apply(Math, maxPriceList.map(item => {item.value}))
    const maxPriceDefault = maxPriceData[maxPriceData.length - 1].value

  const compareByDate = (a, b) => {
    const dateA = a.node.date
    const dateB = b.node.date

    let comparison
    if (dateA < dateB) {
      comparison = 1
    } else if (dateA > dateB) {
      comparison = -1
    }

    return comparison
  }

  useEffect(() => {
    if (
      location.search
        .toLowerCase()
        .split("?key=")
        .join("") == "career" ||
      location.search
        .toLowerCase()
        .split("?key=")
        .join("")
        .replace(/\+/g, " ")
        .includes("career")
    ) {
      let resultValuesTemp = findResult.resultValues
      result &&
        result.map(item => {
          if (item.node.slug == "career") {
            resultValuesTemp.push({
              typeName: item.node.__typename,
              label: item.node.slug,
              title: item.node.title,
              description: item.node.excerpt,
              linkTo: item.node.slug,
            })
            resultValuesTemp.splice(resultValuesTemp.indexOf("career"), 0)
            setFindResult({ ...findResult, resultValues: resultValuesTemp })
          }
        })
    }
  }, [result])

  useEffect(() => {
    if (
      location.search
        .toLowerCase()
        .split("?key=")
        .join("") == "job" ||
      location.search
        .toLowerCase()
        .split("?key=")
        .join("")
        .replace(/\+/g, " ")
        .includes("job")
    ) {
      let resultValuesTemp = findResult.resultValues
      resultValuesTemp.push({
        typeName: "Page",
        label: data.wordPress.page.slug,
        title: data.wordPress.page.title,
        description: data.wordPress.page.excerpt,
        linkTo: data.wordPress.page.slug,
      })
      resultValuesTemp.splice(resultValuesTemp.indexOf("career"), 0)
      setFindResult({ ...findResult, resultValues: resultValuesTemp })
    }
  }, [])

  useEffect(() => {
    if (location.search.split("?key=").length > 1) {
      getResult({
        variables: {
          key:
            location.search.split("?key=").length > 1
              ? location.search
                  .toLowerCase()
                  .split("?key=")
                  .join("")
                  .replace(/\+/g, " ")
                  .includes("career")
                ? "career"
                : location.search.split("?key=").join("")
              : "...",
          language: "EN",
          first: 10,
          last: 0,
          after: "",
          before: "",
        },
      })
    } else if (location.search.split("?key=").length === 1) {
      let facilities = []
      let locations = []
      let propertyTypeId
      let listOfState = {}

      propertyTypeId =
        (location.state &&
          location.state.propertyType &&
          listPropertyTypes.find(x => x.name === location.state.propertyType.label)?.propertyTypeId) ||
        null

      location.state &&
        location.state.values &&
        listLocations.map(
          item =>
            item.name === location?.state?.values?.location?.label &&
            locations.push(item.locationId)
        )
      // eslint-disable-next-line
      location.state &&
        location.state.values &&
        location.state.values.residenceFacilities?.map(item => {
          listFacilities.map(facility => {
            if (facility.slug === item) {
              facilities.push(facility.facilityId)
            }
          })
        })

      setLastLocations(locations)

        console.log(locations);
        console.log("property init", propertyTypeId);
        console.log("facilities init", facilities);
        console.log("location init", location);
        console.log("lastLocations init", lastLocations);

        if(location?.state !== null) {
          getProperty({
            variables: {
              facilityId: facilities.length > 0 ? facilities : null,
              locationId: locations.length > 0 ? locations : null,
              typeId: propertyTypeId ? [propertyTypeId] : defaultPropertyTypesId,
            },
          })
          if(location.state.propertyType){
            console.log("MASUKBRO1")
            listOfState = {...listOfState, propertyTypes: location.state.propertyType}
          }
          if(location?.state?.values){
            if(location.state.values.location){
              console.log("MASUKBRO2")
              listOfState = {...listOfState, location: location.state.values.location}
            }
            if(location.state.values.priceRanges){
              console.log("MASUKBRO3")
              listOfState = {...listOfState,
                fromPrice: location.state.values.priceRanges.fromPrices, 
                toPrice: location.state.values.priceRanges.toPrices, 
              }
            }
            if(location.state.values.residenceFacilities && location.state.values.residenceFacilities.length > 0){
              console.log("MASUKBRO4")
              listOfState = {...listOfState, checkBoxValues: location.state.values.residenceFacilities}
              listOfState.checkBoxValues.map(item => {
                console.log("MASUKBRO5")
                if(item){
                  document.getElementById(item).checked = true
                }else{
                  document.getElementById(item).checked = false
                }
              })
            }else{
              console.log("MASUKBRO6")
              listOfState = {...listOfState, checkBoxValues: []}
            }
            console.log("LOGGERSTATE === ", listOfState)
            setFindProperty(listOfState)
          }

          console.log("LOGGER1 === ", location?.state?.values?.location)
          console.log("LOGGER2 === ", location?.state?.propertyType)
          console.log("LOGGER3 === ", location?.state?.values?.priceRanges)
          console.log("LOGGER4 === ", location?.state?.values?.residenceFacilities)
        }
    }
  }, [lang])

    useEffect(() => {
        console.log("masuk filter price", properties);
        // start filter by priceProduct
        if(properties != undefined){
            console.log("masuk if pertama", location);
            if(location?.state?.values?.priceRanges?.fromPrices !== undefined
                && location?.state?.values?.priceRanges?.toPrices !== undefined){
                console.log("masuk if kedua ", location);
                const projects = []
                for(const prop of propertyFinderResult) {
                    const priceProduct = prop?.node?.priceProduct?.price 
                    if(priceProduct !== null) {
                        if(priceProduct >= location?.state?.values?.priceRanges?.fromPrices 
                            && priceProduct <= location?.state?.values?.priceRanges?.toPrices) {
                            projects.push(prop)
                        }
                    }
                }
                setFilteredProjects(projects);
                setTotal(projects.length)
                console.log("cekLastLocations === ",lastLocations)
                console.log("PROJECTS SAMA DENGAN ", projects.length)

                if (projects.length === 0){
                  console.log("isPROJect 0 >>>> ")
                  if (lastLocations.length > 0){
                    console.log("ADAANMAN >>>> ")
                    setRelatedProjects(propertyFinderResult)
                    setTotalRelated(propertyFinderResult.length)
                  }else{
                    console.log("GAADAMAN <<<< ")
  
                    getProperty({
                      variables: {
                        facilityId: null,
                        locationId: null,
                        typeId: defaultPropertyTypesId,
                      },
                    })
                    
                    const arrayList = []
                    for(const prop of propertyFinderResult.sort(compareByDate)) {
                      arrayList.push(prop)
                    }
                    
                    setRelatedProjects(arrayList.splice(0, 15))
                    setTotalRelated(arrayList.splice(0, 15).length)
                  }
                }
            } else {
                console.log("masuk elif ", location);
                setFilteredProjects(propertyFinderResult)
                setTotal(propertyFinderResult.length)
            }
        }
    }, [properties, isSubmitFilter])

  // search form
  useEffect(() => {
    // console.log('findProperty', findProperty)
    let facilitiesEf = []
    let locationsEf = []
    let propertyTypeIdEf

    propertyTypeIdEf =
    findProperty.propertyTypes &&
      listPropertyTypes.find(x => x.slug === findProperty.propertyTypes.value)
        .propertyTypeId

      findProperty.location &&
      listLocations.map(
        item =>
          item.name === findProperty.location.label &&
          locationsEf.push(item.locationId)
      )

      findProperty.checkBoxValues.length > 0 &&
      findProperty.checkBoxValues.map(item => {
        listFacilities.map(facility => {
          if (facility.slug === item) {
            facilitiesEf.push(facility.facilityId)
          }
        })
      })

    if (locationsEf.length > 0 && propertyTypeIdEf) {
      // console.log('locationsEf', locationsEf)
      // console.log('propertyTypeIdEf', propertyTypeIdEf)
      getProperty({
        variables: {
          locationId: locationsEf.length > 0 ? locationsEf : null,
          typeId: [propertyTypeIdEf],
          facilityId: facilitiesEf.length > 0 ? facilitiesEf : null,
        },
      })
    }
  }, [findProperty])

  useEffect(() => {
    if(resizeScreen){
      document.querySelector(".search-result").style.paddingTop = "0px"
      document.querySelector(".search-result .mw-xl").style.padding = "0px"
      document.querySelector("section.tab-content-section").style.backgroundColor = "white"
      document.querySelector("section.tab-content-section").style.marginBottom = "20px"
    }else{
      document.querySelector(".search-result").style.paddingTop = "5rem"
      document.querySelector(".search-result .mw-xl").style.padding = "0px 20px"
      document.querySelector("section.tab-content-section").style.backgroundColor = "transparent"
      document.querySelector("section.tab-content-section").style.marginBottom = "0px"
    }
  }, [])

  useEffect(() => {
    if (findProperty.location && findProperty.propertyTypes && findProperty.projects) {
      navigate(`/development/${findProperty.propertyTypes.value}/${findProperty.projects.value}`)
    }
  }, [findProperty])

  const locationHandler = e => {
    setFindProperty({ ...findProperty, location: e })
  }

  const propertyTypeHandler = e => {
    // console.log('propertyTypeHandler', propertyTypeHandler)
    setFindProperty({ ...findProperty, propertyTypes: e })
  }

  const projectsHandler = e => {
    setFindProperty({ ...findProperty, projects: e })
  }

  const fromPriceHandler = e => {
    setFindProperty({ ...findProperty, fromPrice: e })
  }

  const toPriceHandler = e => {
    setFindProperty({ ...findProperty, toPrice: e })
  }

  const checkBoxValuesHandler = e => {
    let checkBoxValuesTemp = findProperty.checkBoxValues
    e.checked && checkBoxValuesTemp.push(e.value)
    //prettier-ignore
    !e.checked && checkBoxValuesTemp.splice(checkBoxValuesTemp.indexOf(e.value), 1)
    setFindProperty({ ...findProperty, checkBoxValues: checkBoxValuesTemp })
  }
  // end search form

  const hasPreviousPage = () => {
    if (properties) {
        if(pagination.offset > offset) {
            return true
        }
    } else {
      return false
    }
  }

  const hasNextPage = () => {
    if (properties) {
        if(totalRelated && totalRelated !== 0){
          if(pagination.perPage < totalRelated) {
            return true
          }
        }else{
          if(pagination.perPage < total) {
            return true
          }
        }
    } else {
      return false
    }
  }

  const nextPage = () => {
    if (properties)  {
        setPagination({
            ...pagination,
            offset: pagination.perPage, 
            perPage: pagination.perPage + perPage 
        })
    }
  }

  const previousPage = () => {
    if (properties) {
        setPagination({ 
            ...pagination, 
            offset: pagination.offset - perPage,
            perPage: pagination.perPage - perPage 
        })
    }
  }

  // search form
  const propertyFinderSubmit = e => {
    e.preventDefault()
    if (
      findProperty.location &&
      findProperty.propertyTypes &&
      findProperty.projects
    ) {
        navigate(
            `/development/${findProperty.propertyTypes.value}/${findProperty.projects.value}`
        )
    }

    if(findProperty?.location !== null || findProperty?.projects !== null
        || findProperty?.propertyTypes !== null || findProperty?.checkBoxValues?.length > 0 
        || findProperty?.fromPrice !== null || findProperty?.toPrice !== null) {

        let thisPriceRanges = {}
        let thisLocations = []
        let thispropertyTypesId
        let thisFacilities = []
        let fromPriceValue = 0
        let toPriceValue = 0

        if(location?.state?.values?.priceRanges?.fromPrices !== undefined){
          fromPriceValue = location?.state?.values?.priceRanges?.fromPrices 
          if(findProperty?.fromPrice?.value !== undefined){
            fromPriceValue = findProperty?.fromPrice?.value
          }
        }else{
          fromPriceValue = findProperty?.fromPrice?.value 
        }

        if(location?.state?.values?.priceRanges?.toPrices !== undefined){
          toPriceValue = location?.state?.values?.priceRanges?.toPrices 
          if(findProperty?.toPrice?.value !== undefined){
            toPriceValue = findProperty?.toPrice?.value
          }
        }else{
          toPriceValue = findProperty?.toPrice?.value 
        }

        if((fromPriceValue !== undefined && toPriceValue !== undefined)){
          if(fromPriceValue < toPriceValue){
              thisPriceRanges = {
                fromPrices: fromPriceValue,
                toPrices: toPriceValue,
              }
          }else{
            thisPriceRanges = {
              fromPrices: 0,
              toPrices: toPriceValue,
            }
          }
        }else if(
          (fromPriceValue === undefined && toPriceValue !== undefined)
        ){
          thisPriceRanges = {
            fromPrices: 0,
            toPrices: toPriceValue,
          }
        }else if(
          (fromPriceValue !== undefined && toPriceValue === undefined)
        ){
          thisPriceRanges = {
            fromPrices: fromPriceValue,
            toPrices: maxPriceDefault,
          }
        }
        console.log("findProperty", findProperty)
        console.log("thisPrice", thisPriceRanges)
        console.log("locationBefore", location.state);
        // set the priceRanges to be filtered later on
        // why use location.state? because we use this in the first place. so far this is the easiest way
        if(location?.state?.values?.priceRanges){
            location.state.values.priceRanges = {...thisPriceRanges}
        } else {
            location.state = {
                ...location.state,
                values: {
                    priceRanges: thisPriceRanges
                }
            }
        }
        console.log("locationAfter", location.state);

        thispropertyTypesId =
          ( findProperty?.propertyTypes &&
            listPropertyTypes.find(x => x.name === findProperty?.propertyTypes?.label)?.propertyTypeId) ||
          null

        //set location
        findProperty?.location &&
          listLocations.map(
            item =>
              item.name === findProperty?.location?.label &&
              thisLocations.push(item.locationId)
          )
        //set facilities
        // eslint-disable-next-line
        findProperty?.checkBoxValues &&
          findProperty?.checkBoxValues.map(item => {
            listFacilities.map(facility => {
              if (facility.slug === item) {
                thisFacilities.push(facility.facilityId)
              }
            })
          })

        setLastLocations(thisLocations)

        console.log("thislocations", thisLocations);
        console.log("thispropertyTypeId", thispropertyTypesId);
        console.log("thisfacilities", thisFacilities);
        console.log("thisLastLocations", lastLocations);
        // reset pagination
        setPagination({...pagination, offset, perPage})
        setSubmitFilter(!isSubmitFilter)

        getProperty({
          variables: {
            locationId: thisLocations.length > 0 ? thisLocations : null,
            typeId: thispropertyTypesId ? [thispropertyTypesId] : defaultPropertyTypesId,
            facilityId: thisFacilities.length > 0 ? thisFacilities : null,
          },
        })
    }
  }
  // end search form

  return (
    <Layout location={location}>
      <SEO title="Search Result" />

      <Section className="search-result bg-light" style={{paddingTop: "0px"}}>
        <Container className="mw-xl">
          <section className="tab-content-section">
            <div className="container mw-lg px-lg-up-0">
              <div className="tab-content-wrapper">
                {console.log('listStaticProjects', listStaticProjects)}
                <FormSMLHome
                  locations={listStaticLocations}
                  propertyTypes={listStaticPropertyTypes}
                  projects={listStaticProjects}
                  fromPrice={minPriceData}
                  toPrice={maxPriceData}
                  valueLocation={findProperty.location ? findProperty.location.value : " "}
                  valuePropertyType={findProperty.propertyTypes ? findProperty.propertyTypes.value : ""}
                  valueMinPrice={findProperty.fromPrice ? findProperty.fromPrice : ""}
                  valueMaxPrice={findProperty.toPrice ? findProperty.toPrice : ""}
                  onCheckBoxChange={checkBoxValuesHandler}
                  onLocationChange={locationHandler}
                  onPropertyTypesChange={propertyTypeHandler}
                  onProjectsChange={projectsHandler}
                  onFromPriceChange={fromPriceHandler}
                  onToPriceChange={toPriceHandler}
                  onSubmit={propertyFinderSubmit}
                  isLoading={loadingProperties}
                />
              </div> 
            </div>
          </section>
        </Container>
        <Container className="mw-xl">
          {/* <HeadingBasic h2="Search" /> */}
          {total && total !== 0 ? (
            <h5 className="mb-3 text-muted">{total} results found</h5>
          ) : null}
          {filteredProjects && (
            <div className="row col-md-12 m-0 p-0">
              {filteredProjects && filteredProjects.slice((pagination.offset), pagination.perPage).map((item, i) => {
                  let listFacilitiesProperties = []
                  let thisSubPropertyType = ""

                  // eslint-disable-next-line
                  item?.node?.facilities?.nodes.forEach(listValue => {
                    if(location?.state?.values?.residenceFacilities && location?.state?.values?.residenceFacilities.length > 0){
                      // eslint-disable-next-line
                      location?.state?.values?.residenceFacilities.forEach(resultValue => {
                        if(resultValue === listValue.slug){
                          listFacilitiesProperties.push({
                            image: listValue?.facilityIcon?.icon?.sourceUrl !== null ? listValue?.facilityIcon?.icon?.sourceUrl : "",
                            details: listValue.name,
                          })
                        }
                      })
                    }else if(findProperty?.checkBoxValues && findProperty?.checkBoxValues.length > 0 && findProperty?.checkBoxValues !== " "){
                      let checkedList = findProperty?.checkBoxValues
                      // eslint-disable-next-line
                      checkedList.forEach(item => {
                        if(item && item !== " " && item === listValue.slug){
                          listFacilitiesProperties.push({
                            image: listValue?.facilityIcon?.icon?.sourceUrl !== null ? listValue?.facilityIcon?.icon?.sourceUrl : "",
                            details: listValue.name,
                          })
                        }
                      })
                    }else{
                      listFacilitiesProperties.push({
                        image: listValue?.facilityIcon?.icon?.sourceUrl !== null ? listValue?.facilityIcon?.icon?.sourceUrl : "",
                        details: listValue.name,
                      })
                    }
                  })

                  if (item.node.propertyTypes.nodes[0].name.toLowerCase() === "doubledream property") {
                    listPropertyTypes.forEach(thisItem => {
                      if(thisItem.slug === "doubledream-property"){
                        thisItem.children.nodes.forEach(thisChildren => {
                          thisChildren.projects.nodes.forEach(thisProject => {
                            if(thisProject.slug === item.node.slug){
                              thisSubPropertyType = changeParentSlug(thisChildren.slug)
                            }
                          })
                        })
                      }
                    })
                  }

                  return (
                    <div className="col-md-4" key={`${item?.node?.slug}-${i}`}>
                      <CardSearchPrice
                        forwardKey={`post-${i}`}
                        title={
                          lang === "EN"
                            ? item?.node?.title
                            : item?.node?.translation?.title || item?.node?.title
                        }
                        label={
                          item.node.propertyTypes.nodes[0].name.toLowerCase() ===
                          "doubledream property"
                          ? "Double Dream"
                          : item.node.propertyTypes.nodes[0].name.toLowerCase() ===
                            "infinite living"
                          ? "Infinite Living"
                          : "Development"
                        }
                        labelClassName="mb-2 font-size-sm"
                        locations={
                          item?.node?.locations?.nodes[0]?.name 
                            ? item?.node?.locations?.nodes[0]?.name
                            : "Location"
                        }
                        // locationClassName={}
                        priceProject={item.node.priceProduct.price}
                        // priceProjectClassName={}
                        facilities={listFacilitiesProperties.slice(0,2)}
                        facilitiesClassName="flex-nowrap"
                        img={
                          item?.node?.featuredImage?.sourceUrl
                        }
                        titleClassName="h5 text-truncate-twoline"
                        link={
                          item?.node?.propertyTypes?.nodes[0]?.name?.toLowerCase() ===
                            "doubledream property"
                          ? `/doubledream/${thisSubPropertyType}/${item?.node?.locations?.nodes[0]?.slug}/${item?.node?.slug}/` 
                          : item?.node?.propertyTypes?.nodes[0]?.name?.toLowerCase() ===
                            "infinite living"
                          ? `/development/${item?.node?.propertyTypes?.nodes[1]?.slug}/${item?.node?.slug}/`
                          : `/development/${item?.node?.propertyTypes?.nodes[0]?.name?.toLowerCase()}/${item?.node?.slug}/`
                        }
                        className="card-price-search mb-3"
                      >
                      </CardSearchPrice>
                    </div>
                  )
                })}
            </div>
          )}
          {!loadingProperties &&
            properties !== undefined &&
            filteredProjects.length === 0 && (
            <div className="row col-md-12 m-0 pb-5 pl-0 pr-0 justify-content-center">
              {filteredProjects.length} {lang === "EN" ? "Results Not Found" : "Hasil Tidak Ditemukan"}
            </div>
          )}
          {totalRelated && totalRelated !== 0 ? (
            <h5 className="mb-3 text-muted">{totalRelated} {lang === "EN" ? `results for "related project"` : `hasil untuk "proyek terkait"`}</h5>
          ) : null}
          {relatedProjects && (
            <div className="row col-md-12 m-0 p-0">
              {relatedProjects && relatedProjects.slice((pagination.offset), pagination.perPage).map((item, i) => {
                let listFacilitiesProperties = []
                let thisSubPropertyType = ""

                // eslint-disable-next-line
                item?.node?.facilities?.nodes.forEach(listValue => {
                  if(location?.state?.values?.residenceFacilities && location?.state?.values?.residenceFacilities.length > 0){
                    // eslint-disable-next-line
                    location?.state?.values?.residenceFacilities.forEach(resultValue => {
                      if(resultValue === listValue.slug){
                        listFacilitiesProperties.push({
                          image: listValue?.facilityIcon?.icon?.sourceUrl !== null ? listValue?.facilityIcon?.icon?.sourceUrl : "",
                          details: listValue.name,
                        })
                      }
                    })
                  }else if(findProperty?.checkBoxValues && findProperty?.checkBoxValues.length > 0 && findProperty?.checkBoxValues !== " "){
                    let checkedList = findProperty?.checkBoxValues
                    // eslint-disable-next-line
                    checkedList.forEach(item => {
                      if(item && item !== " " && item === listValue.slug){
                        listFacilitiesProperties.push({
                          image: listValue?.facilityIcon?.icon?.sourceUrl !== null ? listValue?.facilityIcon?.icon?.sourceUrl : "",
                          details: listValue.name,
                        })
                      }
                    })
                  }else{
                    listFacilitiesProperties.push({
                      image: listValue?.facilityIcon?.icon?.sourceUrl !== null ? listValue?.facilityIcon?.icon?.sourceUrl : "",
                      details: listValue.name,
                    })
                  }
                })

                if (item.node.propertyTypes.nodes[0].name.toLowerCase() === "doubledream property") {
                  listPropertyTypes.forEach(thisItem => {
                    if(thisItem.slug === "doubledream-property"){
                      thisItem.children.nodes.forEach(thisChildren => {
                        thisChildren.projects.nodes.forEach(thisProject => {
                          if(thisProject.slug === item.node.slug){
                            thisSubPropertyType = changeParentSlug(thisChildren.slug)
                          }
                        })
                      })
                    }
                  })
                }

                return (
                  <div className="col-md-4" key={`${item?.node?.slug}-${i}`}>
                    <CardSearchPrice
                      forwardKey={`post-${i}`}
                      title={
                        lang === "EN"
                          ? item?.node?.title
                          : item?.node?.translation?.title || item?.node?.title
                      }
                      label={
                        item.node.propertyTypes.nodes[0].name.toLowerCase() ===
                          "doubledream property"
                        ? "Double Dream"
                        : "Development"
                      }
                      labelClassName="mb-2 font-size-sm"
                      locations={
                        item?.node?.locations?.nodes[0]?.name 
                          ? item?.node?.locations?.nodes[0]?.name
                          : "Location"
                      }
                      // locationClassName={}
                      priceProject={item.node.priceProduct.price}
                      // priceProjectClassName={}
                      facilities={listFacilitiesProperties.slice(0,2)}
                      facilitiesClassName="flex-nowrap"
                      img={
                        item?.node?.featuredImage?.sourceUrl
                      }
                      titleClassName="h5 text-truncate-twoline"
                      link={
                        item?.node?.propertyTypes?.nodes[0]?.name?.toLowerCase() ===
                        "doubledream property"
                        ? `/doubledream/${thisSubPropertyType}/${item?.node?.locations?.nodes[0]?.slug}/${item?.node?.slug}/`
                        : `/development/${item?.node?.propertyTypes?.nodes[0]?.name?.toLowerCase()}/${item?.node?.slug}/`
                      }
                      className="card-price-search mb-3"
                    >
                    </CardSearchPrice>
                  </div>
                )
              })}
            </div>
          )}
          <Pagination
            hasPreviousPage={hasPreviousPage()}
            hasNextPage={hasNextPage()}
            prevPage={() => previousPage()}
            nextPage={() => nextPage()}
          />
          {(loadingProperties || loading) && (
            <div className="text-center loader-lg">
              <i class="fa fa-spinner fa-spin"></i>
            </div>
          )}
        </Container>
      </Section>
    </Layout>
  )
}

export default SearchPriceResult
