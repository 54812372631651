import React, { useState } from "react"
import { Helmet } from "react-helmet"
import Modal from "react-modal"

import SEO from "../../../../components/seo"
import Layout from "../../../../components/layout"
import { Section, Container } from "../../../../components/grid/grid"

import vid1 from "../../../../assets/lucky/desktop/2.webm"
import vid2 from "../../../../assets/lucky/desktop/2.5.webm"
import vid3 from "../../../../assets/lucky/desktop/3.webm"
import vid4 from "../../../../assets/lucky/desktop/3.5.webm"
import bgWord from "../../../../assets/lucky/desktop/bg-word.png"
import videoRumah from "../../../../assets/lucky/desktop/before.webm"

import "@glidejs/glide/dist/css/glide.core.min.css"
import "@glidejs/glide/dist/css/glide.theme.min.css"

const DoubleLuckyDrawPopPage = props => {
  const {
    location,
    successModal,
    customStyles,
    handleSuccessModal,
    smsSendComplete,
    setSuccessModal,
    getRandomValue,
    submitData,
    ogUrl,
    phone,
  } = props

  const [videoSrc, setVideoSrc] = useState("")
  const promos = [
    {
      vidSrc: vid1,
      value: "1%",
      doubleValue: "2%",
    },
    {
      vidSrc: vid2,
      value: "1.25%",
      doubleValue: "2.5%",
    },
    {
      vidSrc: vid3,
      value: "1.5%",
      doubleValue: "3%",
    },
    {
      vidSrc: vid4,
      value: "1.75%",
      doubleValue: "3.5%",
    },
  ]

  const handleClick = () => {
    if (!phone) return false
    const randomIndex = getRandomValue()
    const randomVideo = promos[randomIndex]
    setVideoSrc(randomVideo.vidSrc)

    setSuccessModal(true)
    submitData({ data: randomVideo.doubleValue })
  }

  return (
    <Layout location={location} currentLocation={location.uri}>
      <SEO
        title={`Double Lucky Draw, Dapatkan Tambahan Promonya`}
        fbTitle={`Double Lucky Draw, Dapatkan Tambahan Promonya`}
        twitterTitle={`Double Lucky Draw, Dapatkan Tambahan Promonya`}
        description={`Special Promo Sinar Mas Land! Mainkan Double Lucky Draw 2025 dan menangkan tambahan promo special dari Sinar Mas Land`}
        fbDescription={`Special Promo Sinar Mas Land! Mainkan Double Lucky Draw 2025 dan menangkan tambahan promo special dari Sinar Mas Land`}
        twitterDescription={`Special Promo Sinar Mas Land! Mainkan Double Lucky Draw 2025 dan menangkan tambahan promo special dari Sinar Mas Land`}
        url={ogUrl}
        img={`https://www.sinarmasland.com/app/uploads/2024/12/MoveInQuickly-Logo-whiteoutline@2x-1.png`}
        fbImg={`https://www.sinarmasland.com/app/uploads/2024/12/MoveInQuickly-Logo-whiteoutline@2x-1.png`}
        twitterImg={`https://www.sinarmasland.com/app/uploads/2024/12/MoveInQuickly-Logo-whiteoutline@2x-1.png`}
      />
      <Helmet>
        <meta name="Permissions-Policy" content="autoplay=(self)"></meta>
        <body className="bd-page" />
      </Helmet>
      <Section className="sectionSurprise m-0 p-0 text-center">
        <Container
          className="containerSurprise d-flex justify-content-center align-items-end pt-5"
          style={{
            paddingLeft: 80,
            minHeight: "60vh",
          }}
        >
          <div
            style={{
              position: "relative",
              marginBottom: -10,
            }}
          >
            <video
              src={videoRumah}
              autoPlay
              muted
              playsInline
              className="rounded"
              style={{
                width: 500,
                height: 500,
                objectFit: "contain",
                animation: "scaleUp 0.8s ease-out",
              }}
            />
            <div
              onClick={handleClick}
              style={{
                background: "transparent",
                width: 380,
                height: "50%",
                position: "absolute",
                top: 80,
                left: 70,
              }}
            ></div>
          </div>
          <div style={{ marginBottom: 100, flex: 1 }}>
            <img
              src={bgWord}
              alt="Double lucky draw"
              style={{
                width: "100%",
                maxWidth: 500,
              }}
            />
          </div>
        </Container>
      </Section>
      <Modal
        isOpen={successModal}
        style={customStyles}
        contentLabel="result modal"
        overlayClassName="modal-overlay-general"
      >
        <div className="modal-luckydip-success relative modal-body text-center d-flex justify-content-center">
          {/* <img src={imageSuccess} alt="Selamat" /> */}
          <div
            style={{
              position: "relative",
              width: "50%",
              height: "50%",
            }}
          >
            <video
              src={videoSrc}
              autoPlay
              muted
              playsInline
              className="rounded"
              style={{
                width: "100%",
                height: "100%",
                objectFit: "contain",
                animation: "scaleUp 0.8s ease-out",
              }}
            />
            {smsSendComplete ? (
              <button
                className="btn btn-primary btn-close-luckydip"
                style={{
                  position: "absolute",
                  right: 0,
                  top: 0,
                }}
                onClick={handleSuccessModal}
              >
                &times;
              </button>
            ) : null}
          </div>
        </div>
      </Modal>
    </Layout>
  )
}
export default DoubleLuckyDrawPopPage
