import React, { useEffect } from "react"
import Helmet from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"
import { replaceUnicode } from "../../components/utils/utils"
import ReactMarkdown from "react-markdown/with-html"

import "../../graphql/fragments"

import SEO from "../../components/seo"
import Layout from "../../components/layout"
import bgPageDesktop from "../../assets/lucky/desktop/thanks.jpg"
import bgPageMobile from "../../assets/lucky/mobile/thanks.jpg"

import "@glidejs/glide/dist/css/glide.core.min.css"
import "@glidejs/glide/dist/css/glide.theme.min.css"
import { useMediaPredicate } from "react-media-hook"

const ThankyouPage = ({ location }) => {
  const resizeScreen = useMediaPredicate("(max-width: 800px)")
  const bgPage = resizeScreen ? bgPageMobile : bgPageDesktop

  const staticData = useStaticQuery(graphql`
    query {
      wordPress {
        page(id: "thankyou", idType: URI) {
          title
          featuredImage {
            mediaItemUrl
          }
          blocks {
            name
            ... on WordPress_CoreHeadingBlock {
              attributes {
                content
              }
            }
            ... on WordPress_CoreCoverBlock {
              attributes {
                url
                className
              }
            }
            ... on WordPress_CoreParagraphBlock {
              attributes {
                ... on WordPress_CoreParagraphBlockAttributesV3 {
                  content
                }
              }
            }
            ... on WordPress_AcfAcfgalleryBlock {
              name
              acf {
                carousel_type
                images {
                  title
                  mediaDetails {
                    file
                    height
                    width
                  }
                  slug
                  sourceUrl
                  acfAttachment {
                    location
                    expired
                  }
                  caption(format: RAW)
                  description(format: RAW)
                }
              }
            }
            ... on WordPress_CoreEmbedBlock {
              attributes {
                url
              }
            }
          }
          translation(language: ID) {
            title
            blocks {
              name
              ... on WordPress_CoreHeadingBlock {
                attributes {
                  content
                }
              }
              ... on WordPress_CoreCoverBlock {
                attributes {
                  url
                  className
                }
              }
              ... on WordPress_CoreParagraphBlock {
                attributes {
                  ... on WordPress_CoreParagraphBlockAttributesV3 {
                    content
                  }
                }
              }
              ... on WordPress_AcfAcfgalleryBlock {
                name
                acf {
                  carousel_type
                  images {
                    title
                    mediaDetails {
                      file
                      height
                      width
                    }
                    slug
                    sourceUrl
                    acfAttachment {
                      location
                      expired
                    }
                    caption(format: RAW)
                    description(format: RAW)
                  }
                }
              }
              ... on WordPress_CoreEmbedBlock {
                attributes {
                  url
                }
              }
            }
          }
          seo {
            title
            twitterTitle
            twitterDescription
            metaDesc
            opengraphTitle
            opengraphDescription
          }
        }
      }
    }
  `)

  const ogUrl = process.env.BASE_URL + location.pathname + "/"
  const wordPress = staticData.wordPress.page
  const seo = staticData.wordPress.page?.seo

  let coverItems = []
  wordPress &&
    wordPress?.blocks?.map(item => {
      if (item.name === "core/cover") {
        coverItems.push({
          url: item.attributes.url,
          className: item.attributes.className,
        })
      }
    })

  useEffect(() => {
    wordPress &&
      wordPress?.blocks?.map(item => {
        if (item.name === "core/cover") {
          coverItems.push({
            url: item.attributes.url,
            className: item.attributes.className,
          })
        }
      })

    console.log(
      "useEffect purchaseDataLayer",
      sessionStorage.getItem("purchaseDataLayer")
    )
    if (sessionStorage.getItem("purchaseDataLayer") !== null) {
      const dataLayer = JSON.parse(sessionStorage.getItem("purchaseDataLayer"))
      dataLayer.page_title = "Thank You"
      dataLayer.page_url = window.location.href
      window.dataLayer = window.dataLayer || []
      window.dataLayer.push(dataLayer)
      console.log("useEffect dataLayer", dataLayer)
      sessionStorage.removeItem("purchaseDataLayer")
    }
  }, [])

  useEffect(() => {
    let navbarDesktop = document.getElementById("desktopNav").querySelectorAll(".nav-link")
    let vLine = document.querySelector(".v-line")
    let langItem = document.querySelectorAll(".lang-item")
    let langItemActive = document.querySelector(".lang-item.active")
    let tnbHeader = document.querySelector(".tnb-header")

    if(resizeScreen){
      document.getElementsByClassName("logo-secondary")[1].style.display = `none`
      document.getElementsByClassName("logo-main")[1].style.display = `block`
    }else{
      document.getElementsByClassName("logo-secondary")[0].style.display = `none`
      document.getElementsByClassName("logo-main")[0].style.display = `block`
    }

    for (let i = 0; i < navbarDesktop.length; i++) {
      navbarDesktop[i].style.color = "#000"
    }

    for (let i = 0; i < langItem.length; i++) {
      langItem[i].style.color = "#000"
    }

    if (tnbHeader) {
      tnbHeader.id = "tnb-header"
    }

    vLine.style.backgroundColor = "#000"
    langItemActive.style.color = "#AB0204"
    // coverFullTop.style.marginTop = "60px"
  }, [resizeScreen])

  const featuredImage = staticData?.wordPress?.page?.featuredImage?.mediaItemUrl

  return (
    <Layout location={location} currentLocation={location.pathname} lang="EN">
      <SEO
        title={seo?.title}
        fbTitle={seo?.opengraphTitle || seo?.title}
        twitterTitle={seo?.twitterTitle || seo?.title}
        url={ogUrl}
        fbDescription={seo?.opengraphDescription}
        description={seo?.opengraphDescription}
        twitterImg={featuredImage}
        twitterDescription={
          seo?.twitterDescription || seo?.opengraphDescription
        }
        img={featuredImage}
        fbImg={featuredImage}
      />
      <Helmet>
        <body className="bd-page" />
      </Helmet>
      {resizeScreen ? (
        <div className="d-flex justify-content-center">
          <img src={bgPage} alt={wordPress?.title} style={{
            marginTop: "60px",
            maxWidth: "100vw"
          }} />
          {/* <section
            className={`cover cover-img cover-full-top cover-md  ${coverItems[0]?.className}`}
            alt={wordPress?.title}
            style={{
              background: `url(${bgPage}) no-repeat center`,
              backgroundPosition: resizeScreen ? "bottom" : "right",
              height: "50vh",
              width: "60vw",
              backgroundSize: "contain",
              marginTop: 60,
            }}
          ></section> */}
        </div>
      ) : (
        <section
          className={`cover cover-img cover-full-top cover-md  ${coverItems[0]?.className}`}
          alt={wordPress?.title}
          style={{
            background: `url(${bgPage}) no-repeat center`,
            backgroundPosition: "bottom",
            height: "60vh",
            width: resizeScreen ? "50vw" : "100vw",
            backgroundSize: "contain",
            marginTop: 60,
          }}
        ></section>
      )}
    </Layout>
  )
}

export default ThankyouPage
