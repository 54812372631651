import React, { useState, useEffect, useRef, useLayoutEffect } from "react"
// import { Helmet } from "react-helmet"
import { useStaticQuery, graphql, withPrefix } from "gatsby"
import MessengerCustomerChat from "react-messenger-customer-chat"
import { navigate } from "gatsby"
import "lazysizes"
import "lazysizes/plugins/parent-fit/ls.parent-fit"

import NavbarMobile from "./navbar/navbar-mobile"
import NavbarDesktop from "./navbar/navbar-desktop"
import NavbarDesktopAlt from "./navbar/navbar-desktop-alt"
import NavbarMobileAlt from "./navbar/navbar-mobile-alt"
import Footer from "./footer/footer"

import brandLogoDark from "../assets/img/logo_sinarmas-dark.png"
import brandLogoLight from "../assets/img/logo_sinarmas-light.png"

import { getUtmParams } from "../utils/handle-utm"
import { useMediaPredicate } from "react-media-hook"

const Layout = ({
  children,
  currentLocation,
  footerLogo,
  hideFooter,
  altNav,
  isWfh,
  lang,
  location,
  newsLangSlug,
  isDd,
  isAmp,
  isApartemen,
  isWvc,
  isMicrodataProduct,
  isWaForm,
  waFormClick,
  isNews,
  soundFile,
}) => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
        }
      }
      wordPress {
        menus {
          nodes {
            menuItems {
              nodes {
                url
                label
                childItems {
                  nodes {
                    url
                    label
                    childItems {
                      nodes {
                        url
                        label
                      }
                    }
                  }
                }
              }
            }
          }
        }
        propertyTypes(where: { language: EN }, first: 200) {
          nodes {
            id
            name
            slug
            description
            acfPropertyType {
              excerpt
              image {
                sourceUrl
                title
              }
            }
            translation(language: ID) {
              id
              name
              slug
              description
              acfPropertyType {
                excerpt
                image {
                  sourceUrl
                  title
                }
              }
            }
          }
        }
        generalSettings {
          officeAddress
          officeFax
          officePhone
        }
        privacyPolicy: pageBy(uri: "privacy-policy") {
          title
        }
        termAndConditions: pageBy(uri: "term-and-conditions") {
          title
        }
      }
    }
  `)

  const isOpened = useRef(false)
  const mainBodyRef = useRef()
  const [scroll, setScroll] = useState(0)
  const [scrollNews, setScrollNews] = useState(0)
  const resizeScreen = useMediaPredicate("(max-width: 992px)")

  useLayoutEffect(() => {
    const wrapper = document.querySelector("main")
    wrapper.classList.add("animated", "fadeIn")
  }, [])

  useEffect(() => {
    const navLinksDesktop = document.querySelectorAll(
      ".nav-desktop[data-target]"
    )
    const navContents = document.querySelectorAll(
      ".megamenu-desktop[data-content]"
    )
    // Change navbar background color on scroll
    const scrollHandler = () => {
      const scrollCheckDesktop = window.scrollY > 1
      if (scrollCheckDesktop !== scroll) {
        setScroll(scrollCheckDesktop)
      }
    }

    document.addEventListener("scroll", scrollHandler, { passive: true })

    // Transition link
    const wrapper = document.querySelector("main")
    const anchor = document.querySelectorAll("a")
    const body = document.querySelector("body")

    anchor.forEach(item => {
      const navigation = e => {
        const redirect = item.getAttribute("href")
        e.preventDefault()

        navContents.forEach(item => {
          item.classList.remove("show")
        })
        body.classList.remove("bd-page")
        wrapper.classList.remove("fadeIn")
        wrapper.classList.add("fadeOut")

        setTimeout(() => {
          if (lang === "ID") {
            let isId = redirect && redirect.split("/id").length > 1
            let isExternal = redirect && redirect.split("https").length > 1

            if (!isId && !isExternal) navigate(`/id${redirect}`)
          } else navigate(redirect)
        }, 250)
      }
      if (
        item.getAttribute("href") &&
        !item.getAttribute("target") &&
        !item.getAttribute("download") === null
      ) {
        item.addEventListener("click", navigation)
      }
    })

    // =========== Desktop megamenu toggle ===========
    const openMegamenu = e => {
      if (!e.classList.contains("show")) {
        navContents.forEach(item => {
          item.classList.remove("show")
        })
        e.classList.add("show")
        isOpened.current = true
      } else {
        e.classList.remove("show")
        isOpened.current = false
      }
    }

    const activeToggler = currentItem => {
      if (!currentItem.classList.contains("active")) {
        navLinksDesktop.forEach(item => {
          item.classList.remove("active")
        })
        currentItem.classList.add("active")
      } else {
        currentItem.classList.remove("active")
      }
    }

    const closeOnClickOutside = () => {
      navContents.forEach(item => {
        item.classList.remove("show")
      })
      navLinksDesktop.forEach(item => {
        item.classList.remove("active")
      })
    }

    navLinksDesktop.forEach(nav => {
      const target = document.querySelector(
        `${nav.dataset.target}.megamenu-desktop`
      )

      nav.addEventListener("click", e => {
        e.preventDefault()
        openMegamenu(target)
        activeToggler(nav)
      })
    })

    wrapper.addEventListener("click", closeOnClickOutside)

    // =========== Mobile megamenu toggle ===========
    const navLinksMobile = document.querySelectorAll(".nav-mobile[data-target]")
    const navContentsMobile = document.querySelectorAll(
      ".navbar-slide[data-content]"
    )
    const navBtnBack = document.querySelectorAll(".navBack")

    navLinksMobile.forEach(nav => {
      const target = document.querySelector(
        `#${nav.dataset.target}.navbar-slide`
      )
      const clickHandler = () => {
        target.classList.add("show")
      }
      nav.addEventListener("click", clickHandler)
    })

    navBtnBack.forEach(btn => {
      btn.addEventListener("click", () => {
        navContentsMobile.forEach(content => content.classList.remove("show"))
      })
    })

    return () => {
      anchor.forEach(item => {
        const navigation = e => {
          const redirect = item.getAttribute("href")
          e.preventDefault()

          navContents.forEach(item => {
            item.classList.remove("show")
          })
          body.classList.remove("bd-page")
          wrapper.classList.remove("fadeIn")
          wrapper.classList.add("fadeOut")

          setTimeout(() => {
            navigate(redirect)
          }, 250)
        }
        if (item.getAttribute("href") && !item.getAttribute("target")) {
          item.removeEventListener("click", navigation)
        }
      })

      document.removeEventListener("scroll", scrollHandler, { passive: true })

      navLinksDesktop.forEach(nav => {
        const target = document.querySelector(
          `${nav.dataset.target}.megamenu-desktop`
        )

        nav.removeEventListener("click", e => {
          e.preventDefault()
          openMegamenu(target)
          activeToggler(nav)
        })
      })

      navLinksMobile.forEach(nav => {
        const target = document.querySelector(
          `#${nav.dataset.target}.navbar-slide`
        )
        const clickHandler = () => target.classList.add("show")
        nav.removeEventListener("click", clickHandler)
      })

      navBtnBack.forEach(btn => {
        btn.removeEventListener("click", () => {
          navContentsMobile.forEach(content => content.classList.remove("show"))
        })
      })

      wrapper.removeEventListener("click", closeOnClickOutside)
    }
  }, [])

  useEffect(() => {
    const desktopNav = document.querySelector("#desktopNav")
    const relatedNewsWrapper = document.querySelector(".related-news-wrapper")
    const adsNewsWrapper = document.querySelector(".news-ads-wrapper")
    const postDetails = document.querySelector(".post-details")
    const navLinksDesktop = document.querySelectorAll(
      ".nav-desktop[data-target]"
    )
    const body = document.querySelector("main")

    // scroller news handler
    const scrollNewsHandler = () => {
      const scrollCheckNews = window.scrollY > 10
      if (scrollCheckNews !== scrollNews) {
        setScrollNews(scrollCheckNews)
      }
    }

    const toggleBackground = () => {
      if (!scroll && isOpened.current) {
        desktopNav.classList.add("is-scrolled")
      } else if (!scroll && desktopNav.classList.contains("is-scrolled")) {
        desktopNav.classList.remove("is-scrolled")
      }
    }

    navLinksDesktop.forEach(nav => {
      nav.addEventListener("click", toggleBackground)
    })

    const closeOnClickOutside = () => {
      if (!scroll) {
        desktopNav.classList.remove("is-scrolled")
      }
    }

    body.addEventListener("click", closeOnClickOutside)
    document.addEventListener("scroll", scrollNewsHandler, { passive: true })

    if (isNews) {
      if (!resizeScreen) {
        if (!scrollNews) {
          postDetails.classList.remove("wScroll")
          relatedNewsWrapper.classList.remove("is-scrolled-relatedNews")
          adsNewsWrapper.classList.remove("is-scrolled-adsNews")
        } else {
          postDetails.classList.add("wScroll")
          relatedNewsWrapper.classList.add("is-scrolled-relatedNews")
          adsNewsWrapper.classList.add("is-scrolled-adsNews")
        }
      }
    }

    return () => {
      navLinksDesktop.forEach(nav => {
        nav.removeEventListener("click", toggleBackground)
      })
      body.removeEventListener("click", closeOnClickOutside)
      document.addEventListener("scroll", scrollNewsHandler, { passive: true })
    }
  }, [scroll, scrollNews])

  const bgColorChange = scroll ? "is-scrolled" : ""

  useEffect(() => {
    getUtmParams(window.location.search)
    const splitUndefined = window.location.search
    const pathname = window.location.pathname
    if (splitUndefined === "?undefined=undefined") navigate(pathname)
  }, [])

  useEffect(() => {
    const pathIdentifier = "/amp/"
    const pathname = window.location.pathname
    if (pathname.indexOf(pathIdentifier) > -1) {
      // import (`../assets/scss/amp.scss`)
      console.log("amp has deployed")
    } else {
      import(`../assets/scss/main.scss`)
    }
  }, [])

  // if key param in search result enable
  useEffect(() => {
    const paramUrl = window.location.search
    if (paramUrl && paramUrl.includes("?key=")) {
      sessionStorage.setItem("search_key", paramUrl.split("?key=").join(""))
    }
  }, [])

  useLayoutEffect(() => {
    if (isAmp) {
      const htmlTag = document.querySelector("html")
      htmlTag.setAttribute("amp", "")
    }
  }, [isAmp])

  return (
    <>
      {/* <Helmet>
        {!isAmp ? (<script src={withPrefix("qualtrics.js")} type="text/javascript" defer/>):("")}
      </Helmet> */}
      <div id="ZN_bPDQGqPGBHECKBE"></div>
      <div className="navbar-wrapper fixed">
        {!altNav && (
          <>
            <NavbarDesktop
              brandLogoMain={brandLogoDark}
              brandLogoSecondary={brandLogoLight}
              brandLogoAlt={data.site.siteMetadata.title}
              navBackground={bgColorChange}
              url={currentLocation}
              fetchedData={data.wordPress}
              newsLangSlug={newsLangSlug}
              lang={lang}
              isDd={isDd}
              isAmp={isAmp}
              isApartemen={isApartemen}
              soundFile={soundFile}
            />
            <NavbarMobile
              brandLogoMain={brandLogoDark}
              brandLogoSecondary={brandLogoLight}
              brandLogoAlt={data.site.siteMetadata.title}
              url={currentLocation}
              navBackground={bgColorChange}
              fetchedData={data.wordPress}
              newsLangSlug={newsLangSlug}
              lang={lang}
              isDd={isDd}
              isAmp={isAmp}
              isApartemen={isApartemen}
              isWvc={isWvc}
              soundFile={soundFile}
            />
          </>
        )}
        {altNav && (
          <>
            <NavbarDesktopAlt
              brandLogoMain={brandLogoDark}
              brandLogoSecondary={brandLogoLight}
              brandLogoAlt={data.site.siteMetadata.title}
              navBackground={bgColorChange}
              url={currentLocation}
              fetchedData={data.wordPress}
            />
            <NavbarMobileAlt
              brandLogoMain={brandLogoDark}
              brandLogoSecondary={brandLogoLight}
              brandLogoAlt={data.site.siteMetadata.title}
              url={currentLocation}
              navBackground={bgColorChange}
              fetchedData={data.wordPress}
            />
          </>
        )}
      </div>
      {isMicrodataProduct ? (
        <main ref={mainBodyRef} itemType="https://schema.org/Product" itemScope>
          {children}
        </main>
      ) : (
        <main ref={mainBodyRef}>{children}</main>
      )}
      {/* <MessengerCustomerChat pageId="124976984283044" appId="401011690922768" /> */}
      {!hideFooter && (
        <Footer
          showLogo={footerLogo}
          fetchedData={data.wordPress}
          isWfh={isWfh}
          location={location}
          newsLangSlug={newsLangSlug}
          isAmp={isAmp}
          isWaForm={isWaForm}
          waFormClick={waFormClick}
        />
      )}
    </>
  )
}

export default Layout
